<template>
  <div style="overflow-x: hidden">
    <div ref="img">
      <Bj :bjData="bjData" />
    </div>
    <!-- 关于聚趣 -->
    <div ref="juqu" class="juqu_out">
      <div class="juqu">
        <div class="bg_title_out flexCenter">
          <div class="bg_title bg_title_apear">
            <div>
              <span>A</span>
              BOUT
            </div>
          </div>
          <div class="title_out">
            <div class="big_title title_apear">关于聚趣</div>
          </div>
        </div>
        <div class="juqu_content_out">
          <div class="juqu_content">
            <div class="juqu_content_intro">
              聚趣是一家为全球用户提供一站式在线语言学习的数字化平台。
            </div>
            <div class="juqu_content_desc">
              公司围绕，教育，科技，服务三大核心内核。利用互联网，结合大数据，人工智能和语言科学，探索未来教育新模式的科技机构。
            </div>
            <div class="juqu_content_type flexBetween">
              <div
                class="juqu_content_type_item"
                v-for="(item, i) in juquContentType"
                :key="i"
              >
                <img :src="item.img" alt="" />
                <div class="juqu_content_type_item_value flexCenter">
                  <span>{{ item.value }}</span>
                  {{ item.value2 }}
                </div>
                <div class="juqu_content_type_item_name">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <img
            class="juqu_content_img"
            mode="widthFixe"
            src="@/assets/mobile/home/juqu_content_img.png"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="juqu_app">
				<img
					:class="'img_apear juqu_app_img item_img' + (i + 1)"
					v-for="(item, i) in juquAppImgList"
					:key="i"
					:src="item"
					alt=""
				/>
			</div> -->
    </div>
    <!-- 聚趣名师 -->
    <div ref="teacher" class="teacher_out">
      <div class="bg_title_out flexCenter">
        <div
          :class="
            scrollItem == 'teacher' && teacherTime == 0
              ? 'bg_title bg_title_apear'
              : 'bg_title'
          "
        >
          <!-- 
					:style="{
						opacity: teacherTime == 1 ? '0.1' : '0',
						'animation-delay': '0ms',
					}" -->
          <div>
            <span>T</span>
            EACHER
          </div>
        </div>
        <div class="title_out">
          <div
            :class="
              scrollItem == 'teacher' && teacherTime == 0
                ? 'big_title title_apear'
                : 'big_title'
            "
          >
            <!-- :style="{
							opacity: teacherTime == 1 ? '1' : '0',
							'animation-delay': '45ms',
						}" -->
            聚趣名师
          </div>
          <div
            :class="
              scrollItem == 'teacher' && teacherTime == 0
                ? 'small_title title_apear'
                : 'small_title'
            "
          >
            <!-- :style="{
							opacity: teacherTime == 1 ? '1' : '0',
							'animation-delay': '90ms',
						}" -->
            甄选全国资深教师
          </div>
        </div>
      </div>
      <div class="teacher_classification_out flexVerticalCenter">
        <vue-scroll :ops="ops" style="width: 100%; height: 100%">
          <div class="teacher_classification_list flexCenter">
            <div
              class="teacher_classification flexVerticalCenter"
              :style="{
                fontWeight: teacherClassification == item.appName ? 'bold' : '400',
              }"
              v-for="(item, i) in classificationList"
              :key="'sh' + i"
              v-show="item.showTag == 1"
              @click="selectTeacherClassification(item)"
            >
              {{ item.appName }}
              <div
                v-if="teacherClassification == item.appName"
                class="teacher_classification_active"
              ></div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <div v-if="teacherInfo && teacherInfo.name" class="teacher_info">
        <div class="teacher_img_out">
          <img class="teacher_bg" src="@/assets/mobile/home/teacher_bg.png" alt="" />
          <img
            v-if="teacherInfo.pictureMobile"
            class="teacher_img"
            mode="widthFix"
            :src="teacherInfo.pictureMobile"
            alt=""
          />
          <div class="teacher_img_out2 flexVerticalCenter" v-else>
            <img
              class="teacher_img"
              mode="widthFix"
              style="width: 55.999%"
              :src="teacherInfo.pictureBig"
              alt=""
            />
          </div>
          <div class="teacher_name_out">
            <img
              class="teacher_name_bg"
              mode="widthFix"
              src="@/assets/mobile/home/teacher_name_bg.png"
              alt=""
            />
            <div class="teacher_name_box">
              <div class="teacher_name">
                {{ teacherInfo.name }}
              </div>
              <div class="teacher_des">
                {{ teacherInfo.typeName }}
              </div>
            </div>
          </div>
        </div>
        <div class="teacher_details">
          <div class="teacher_introduce">
            {{ teacherInfo.intro }}
          </div>
        </div>
      </div>
      <!-- <div class="teacher_text_classification">
				{{ teacherClassificationE }}
			</div> -->
      <!-- <div class="teacher_list_out_out">
				<vue-scroll
					class="teacher_list_out"
					:ops="ops"
					style="width:100%;height:100%;"
				>
					<div class="teacher_list flexVerticalCenter">
						<img
							:class="
								teacherIndex == i
									? 'teacher_imgs teacher_active'
									: 'teacher_imgs'
							"
							:src="item.pictureSmall"
							alt=""
							v-for="(item, i) in teacherList"
							:key="i"
							@click="selectTeacherInfo(i, item)"
						/>
					</div>
				</vue-scroll>
			</div> -->
      <div class="teacher_list_out_out flexBetween" style="min-height: 52px">
        <div class="teacher_list_out flexBetween" style="width: 100%; min-height: 52px">
          <div class="teacher_list" style="width: 100%">
            <div
              class="teacher_imgs2 flexVerticalCenter"
              v-for="(item, i) in teacherList"
              :key="i"
              @click="selectTeacherInfo(i, item)"
            >
              <img
                style="margin: 0; width: 100%; height: 100%"
                :src="item.pictureSmall"
                alt=""
              />
              <div class="teacher_imgs2_bg"></div>
              <div
                class="teacher_imgs2_bg teacher_imgs2_bg2"
                v-if="teacherIndex == i"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 聚趣小语种 -->
    <div ref="language" class="language_out">
      <div class="bg_title_out flexCenter">
        <div
          :class="
            scrollItem == 'language' && languageTime == 0
              ? 'bg_title bg_title_apear'
              : 'bg_title'
          "
        >
          <!-- :style="{
						opacity: languageTime == 1 ? '0.1' : '0',
						'animation-delay': '0ms',
					}" -->
          <div>
            <span>L</span>
            ANGUAGE
          </div>
        </div>
        <div class="title_out">
          <div
            :class="
              scrollItem == 'language' && languageTime == 0
                ? 'big_title title_apear'
                : 'big_title'
            "
          >
            <!-- :style="{
							opacity: languageTime == 1 ? '1' : '0',
							'animation-delay': '45ms',
						}" -->
            科技驱动
          </div>
          <div
            :class="
              scrollItem == 'language' && languageTime == 0
                ? 'small_title title_apear'
                : 'small_title'
            "
          >
            <!-- :style="{
							opacity: languageTime == 1 ? '1' : '0',
							'animation-delay': '90ms',
						}" -->
            深耕数字语言内容
          </div>
        </div>
      </div>
      <div class="language">
        <img src="@/assets/pc/home/language_img.png" alt="" />
      </div>
    </div>
    <!-- 媒体报道 -->
    <div ref="media" class="media_out">
      <div class="bg_title_out flexCenter">
        <div
          :class="
            scrollItem == 'media' && mediaTime == 0
              ? 'bg_title bg_title_apear'
              : 'bg_title'
          "
        >
          <div>
            <span>M</span>
            EDIA
          </div>
        </div>
        <div class="title_out">
          <div
            :class="
              scrollItem == 'media' && mediaTime == 0
                ? 'big_title title_apear'
                : 'big_title'
            "
          >
            媒体关注与报道
          </div>
        </div>
      </div>
      <div class="media_list">
        <img
          @click="goToWebsite(item.url)"
          class="media_item"
          :style="{
            cursor: item.url ? 'pointer' : '',
            marginRight: parseInt(i + 1) % 3 == 0 ? '0' : '',
          }"
          mode="widthFix"
          :src="item.img"
          alt=""
          v-for="(item, i) in mediaList"
          :key="i"
        />
      </div>
    </div>
  </div>
</template>

<script>
// const axios = require('axios')
import Bj from "@/components/mobile/bj.vue";
export default {
  components: {
    Bj,
  },
  data() {
    return {
      bjData: {
        imgUrl: "https://qn.juquedu.com/bj_mobile.png",
        title: "选择聚趣",
        minTitle: "让学习更有趣",
      },
      modularList: [
        { name: "img", scroll: "" },
        { name: "juqu", scroll: "" },
        { name: "teacher", scroll: "" },
        { name: "language", scroll: "" },
        { name: "media", scroll: "" },
      ],
      scrollItem: "img",
      juquImg: false,
      juquTime: 0,
      studyTime: 0,
      teacherTime: 0,
      languageTime: 0,
      mediaTime: 0,
      isVideo: false,
      juquContentType: [
        {
          img: require("@/assets/pc/home/juqu_content_1.png"),
          value: "800",
          value2: "万节",
          name: "完课累计",
        },
        {
          img: require("@/assets/pc/home/juqu_content_2.png"),
          value: "400",
          value2: "万",
          name: "用户选择",
        },
        {
          img: require("@/assets/pc/home/juqu_content_3.png"),
          value: "34",
          value2: "个",
          name: "国家地区学员遍布",
        },
        {
          img: require("@/assets/pc/home/juqu_content_4.png"),
          value: "8",
          value2: "年",
          name: "品牌沉淀",
        },
      ],
      juquAppImgList: [
        require("@/assets/mobile/home/left_1.png"),
        require("@/assets/mobile/home/left_2.png"),
        require("@/assets/mobile/home/left_3.png"),
        require("@/assets/mobile/home/right_1.png"),
        require("@/assets/mobile/home/left_1.png"),
        require("@/assets/mobile/home/right_2.png"),
        require("@/assets/mobile/home/right_3.png"),
      ],
      stduyList: [
        {
          img: require("@/assets/mobile/home/study_1.png"),
          title: "留学名师",
          des: "留学名师助力，养成优异学习方法",
          content:
            "海外留学经历，多年教学经验，国内名校毕业辅导老师课后辅导，课后难题全解答。教研团队潜心钻研，考试难重点轻松击破。",
        },
        {
          img: require("@/assets/mobile/home/study_2.png"),
          title: "质量把控",
          des: "60天考核机制，优中选优",
          content:
            "60天教师培训，自主备课，模拟上课。每月教师抽查机制，让课程质量更加提升。权威机构合作，课程结合知名学府学习方法",
        },
        {
          img: require("@/assets/mobile/home/study_3.png"),
          title: "结合科技",
          des: "科技助教育乘风破浪",
          content:
            "智能学习系统，优化练习，根据学习习惯生成题型科学算法，为用户推荐更适合的学习内容。结合科技，为你的学习提升添砖加瓦",
        },
        {
          img: require("@/assets/mobile/home/study_4.png"),
          title: "选择全面",
          des: "多种语言任你选择",
          content:
            "兼顾考试、留学、工作、兴趣、实用....\n课程种类丰富。\n只要你想学，我们都会有。",
        },
      ],
      classificationList: [
        { id: 1, appName: "德" },
        { id: 2, appName: "法" },
        { id: 5, appName: "西" },
        { id: 4, appName: "韩" },
        { id: 3, appName: "日" },
        { id: 6, appName: "雅思" },
        { id: 7, appName: "俄" },
      ],
      teacherClassification: "德",
      teacherClassificationE: "GERMAN",
      teacherInfo: {},
      teacherList: [],
      teacherIndex: 0,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#707070", //滚动条颜色
          opacity: 0, //滚动条透明度
          "overflow-y": "hidden",
        },
      },
      languageList: [
        { chinese: "芬兰语", original: "Suomi kieli" },
        { chinese: "德语", original: "Deutsch" },
        { chinese: "法语", original: "Français" },
        { chinese: "西班牙语", original: "Español" },
        { chinese: "瑞典语", original: "svenska" },
        { chinese: "日语", original: "にほんご" },
        { chinese: "韩语", original: "한국어" },
        { chinese: "俄语", original: "Русский язык" },
        { chinese: "阿拉伯语", original: "لغة عربية" },
        { chinese: "意大利语", original: "Italiano" },
        { chinese: "波兰语", original: "Polski" },
        { chinese: "泰语", original: "ภาษาไทย" },
      ],
      mediaList: [
        {
          name: "新浪",
          img: require("@/assets/pc/home/media_1.png"),
          url:
            "https://news.sina.com.cn/shangxunfushen/2024-04-15/detail-inarwyix1637812.shtml",
        },
        {
          name: "凤凰网",
          img: require("@/assets/pc/home/media_5.png"),
          url: "https://tech.ifeng.com/c/8ZMe8s3ykUR",
        },
        {
          name: "搜狐新闻",
          img: require("@/assets/pc/home/media_9.png"),
          url: "https://biznews.sohu.com/a/781015626_120181749",
        },
        {
          name: "网易",
          img: require("@/assets/pc/home/media_11.png"),
          url: "https://www.163.com/news/article/J40TG08Q00019UD6.html",
        },
        {
          name: "腾讯新闻",
          img: require("@/assets/pc/home/media_10.png"),
          url: "https://new.qq.com/rain/a/20231031A084AV00",
        },
        {
          name: "zaker",
          img: require("@/assets/pc/home/media_15.png"),
          url:
            "https://app.myzaker.com/news/article.php?m=1715050795&pk=663996401bc8e0be6b000007",
        },
        {
          name: "腾讯教育",
          img: require("@/assets/pc/home/media_7.png"),
          url:
            "https://view.inews.qq.com/k/20231125A0545P00?no-redirect=1&web_channel=wap&openApp=false",
        },
        {
          name: "中国日报",
          img: require("@/assets/pc/home/media_14.png"),
          url:
            "https://qiye.chinadaily.com.cn/a/202405/07/WS66399d24a3109f7860ddc6d5.html",
        },
        {
          name: "CCTV新闻网",
          img: require("@/assets/pc/home/media_16.png"),
          url: "http://cctvnews.wang/jiaoyu/2024-05-23/236.html",
        },
        {
          name: "中华网",
          img: require("@/assets/pc/home/media_17.png"),
          url: "https://hea.china.com/article/20240415/042024_1505885.html",
        },
        {
          name: "凤凰网科技",
          img: require("@/assets/pc/home/media_8.png"),
          url: "https://tech.ifeng.com/c/8YnpYrbGH5N",
        },
        {
          name: "大众网",
          img: require("@/assets/pc/home/media_18.png"),
          url: "https://www.dzwww.com/xinwen/jishixinwen/202406/t20240606_14292465.htm",
        },
        {
          name: "聪慧教育网",
          img: require("@/assets/pc/home/media_13.png"),
          url: "https://edu.hczyw.com/2024/0415/73172.html",
        },
        { name: "36kr", img: require("@/assets/pc/home/media_2.png"), url: "" },
        {
          name: "环球网",
          img: require("@/assets/pc/home/media_3.png"),
          url: "",
        },
        {
          name: "华尔街见闻",
          img: require("@/assets/pc/home/media_4.png"),
          url: "",
        },
        {
          name: "钛媒体",
          img: require("@/assets/pc/home/media_6.png"),
          url: "",
        },
        {
          name: "芥末堆",
          img: require("@/assets/pc/home/media_12.png"),
          url: "",
        },
      ],
      gTeacherList: [],
      fTeacherList: [
        // 男
        {
          intro:
            "法语教龄11年\n法语专八/TCF C1\n曾任国内知名法语培训机构教师，深受学生喜爱\n擅长法语听力/口语/阅读/写作等课程\n授课方式严谨细致，注重学生基础知识的打牢。",
          languageid: 5,
          name: "罗老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nan_luo.png",
          typeName: "聚趣法语高级讲师",
        },
        {
          intro:
            "法语教龄10年\n法语专八/TCF C1\n天津外国语大学硕士\n原某知名留学中介法语导师\n擅长法语词汇、语法课程、法语专四/专八考试课程",
          languageid: 5,
          name: "王老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nan_wang.png",
          typeName: "聚趣法语优秀讲师",
        },
        // 女
        {
          intro:
            "法语教龄11年\n法语专八/TCF C1\n北京大学硕士\n原新东方在线知名法语讲师\n擅长法语词汇、语法课程、法语专四/专八考试课程",
          languageid: 5,
          name: "张老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nv_zhang.png",
          typeName: "聚趣法语高级讲师",
        },
        {
          intro:
            "法语教龄9年\n法语专八/TCF C1\n法国图卢兹大学硕士\n擅长法语口语/听力训练、商务法语、旅游法语等课程\n授课方式生动有趣，深受学员喜爱。",
          languageid: 5,
          name: "黄老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nv_huang.png",
          typeName: "聚趣法语人气讲师",
        },
        {
          intro:
            "法语教龄7年\n法语专八/TCF C1\n华中师范大学硕士\n原法国巴黎高等师范学院留学生导师\n擅长法语词汇、语法课程、法语专四/专八考试课程",
          languageid: 5,
          name: "叶老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nv_ye.png",
          typeName: "聚趣法语优秀讲师",
        },
        {
          intro:
            "法语教龄9年\n法语专八/TCF C1\n复旦大学硕士\n原某知名留学中介法语导师\n擅长法语词汇、语法课程、法语专四/专八考试课程",
          languageid: 5,
          name: "左老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nv_zuo.png",
          typeName: "聚趣法语优秀讲师",
        },
        // 男
        {
          intro:
            "法语教龄9年\n法语专八/TCF C1\n华东师范大学硕士\n擅长法语A1-B2课程、法语考研二外课程、法语四级考试课程",
          languageid: 5,
          name: "彭老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nan_peng.png",
          typeName: "聚趣法语人气讲师",
        },
        {
          intro:
            "法语教龄8年\n法语专八/TCF C1\n浙江大学硕士\n擅长法语口语、听力课程、法语专四/专八考试课程",
          languageid: 5,
          name: "张老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nan_zhang.png",
          typeName: "聚趣法语优秀讲师",
        },
        // 女
        {
          intro:
            "法语教龄6年\n法语专八/TCF C1\n复旦大学硕士\n擅长TCF/TEF考试课程、法语口语训练等课程\n授课方式亲和力强，注重学生实际语言运用能力的提升",
          languageid: 5,
          name: "倪老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nv_ni.png",
          typeName: "聚趣法语优秀讲师",
        },
        {
          intro:
            "法语教龄8年\n法语专八/TCF C1\n南京大学硕士\n原法国南特大学教师\n擅长法语翻译、口译课程、法语专四/专八考试课程",
          languageid: 5,
          name: "李老师",
          pictureBig: "https://qn.juquedu.com/f_tch_nv_li.png",
          typeName: "聚趣法语口碑讲师",
        },
      ],
      jTeacherList: [
        // 女
        // {
        // 	intro:
        // 		'日语教龄5年\n北京外国语大学日语专业\n曾赴日本东京大学留学\n教学经验丰富，授课热情洋溢，深受学生喜爱\n擅长日语口语和听力课程',
        // 	languageid: 3,
        // 	name: 'Yuki老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_yuki.png',
        // 	typeName: '聚趣日语明星讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄8年\n北京语言大学日语专业\n曾在日本京都大学交流学习\n授课通俗易懂，注重培养学生的语感和语音准确性\n擅长日语语法和阅读课程',
        // 	languageid: 3,
        // 	name: '吴老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_wu.png',
        // 	typeName: '聚趣日语资深讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄10年\n上海外国语大学日语专业\n曾在日本国立东京大学攻读硕士学位\n教学经验丰富，擅长培养学生的学习习惯和方法\n擅长日语写作和翻译课程',
        // 	languageid: 3,
        // 	name: 'Ayaka老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_ayaka.png',
        // 	typeName: '聚趣日语高级讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄6年\n南京外国语学院日语专业\n曾在日本名古屋大学留学\n授课亲和力强，以学生为中心，注重培养学生的自主学习能力\n擅长日语口语和听力课程',
        // 	languageid: 3,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_wang.png',
        // 	typeName: '聚趣日语资深讲师'
        // },
        // // 男
        // {
        // 	intro:
        // 		'日语教龄5年\n日语N2\n北京外国语大学本科，日本立教大学留学经历\n曾在日本留学期间执教多年，对日本文化和语言有深入的了解\n擅长日语口语课程、商务日语课程、日语考试培训',
        // 	languageid: 3,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nan_zhang.png',
        // 	typeName: '聚趣日语口语讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄6年\n日语N1\n曾赴日本东京大学留学\n上海外国语大学本科，日本大阪大学留学经历\n曾在日本大学执教多年，对日本语言和文化有深入的理解\n擅长日语口语课程、日语听力训练、日语考试培训',
        // 	languageid: 3,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nan_wang.png',
        // 	typeName: '聚趣日语口语讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄7年\n日语N1\n南京外国语大学本科，日本东京大学留学经历\n曾在日本留学期间执教多年，对日本语言和文化有深入的了解\n擅长日语口语课程、日语写作训练、日语考试培训',
        // 	languageid: 3,
        // 	name: '陈老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nan_chen.png',
        // 	typeName: '聚趣日语口语讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄9年\n复旦大学日语专业\n曾在日本东京大学交流学习\n授课风格严谨，注重培养学生的语言运用能力和文化素养\n擅长日语语法和阅读课程',
        // 	languageid: 3,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_zhang.png',
        // 	typeName: '聚趣日语资深讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄7年\n上海外国语大学日语专业\n曾在日本庆应义塾大学攻读硕士学位\n授课思路清晰，注重培养学生的实际应用能力和交际能力\n擅长日语听力和口语课程',
        // 	languageid: 3,
        // 	name: '米娅老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_miya.png',
        // 	typeName: '聚趣日语资深教师'
        // },
        // {
        // 	intro:
        // 		'日语教龄11年\n北京外国语大学日语专业\n曾在日本东京外国语大学留学\n教学经验丰富，授课热情洋溢，深受学生喜爱\n擅长日语口语和听力课程',
        // 	languageid: 3,
        // 	name: '杨老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_yang.png',
        // 	typeName: '聚趣日语高级讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄8年\n山东大学日语专业\n曾在日本大阪大学攻读硕士学位\n授课方式灵活多样，注重培养学生的实际应用能力和交际能力\n擅长日语写作和翻译课程',
        // 	languageid: 3,
        // 	name: '赵老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_zhao.png',
        // 	typeName: '聚趣日语资深教师'
        // },
        // {
        // 	intro:
        // 		'日语教龄1年\n武汉大学日语专业\n曾在日本神户大学留学\n教学经验丰富，授课热情洋溢，注重培养学生的语感和语音准确性\n擅长日语语法和阅读课程',
        // 	languageid: 3,
        // 	name: '郑老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_deng.png',
        // 	typeName: '聚趣日语高级讲师'
        // },
        // {
        // 	intro:
        // 		'日语教龄5年\n北京外国语大学日语专业\n曾赴日本东京大学留学\n教学经验丰富，授课热情洋溢，深受学生喜爱\n擅长日语口语和听力课程',
        // 	languageid: 3,
        // 	name: '刘老师',
        // 	pictureBig: 'https://qn.juquedu.com/j_tch_nv_liu.png',
        // 	typeName: '聚趣日语明星讲师'
        // }
      ],
      kTeacherList: [
        // 男
        // {
        // 	intro:
        // 		'韩语教龄11年\n曾在韩国多所大学进行交流研讨，深入了解韩国语言和文化\n擅长韩语中高级课程和TOPIK考试辅导，能够帮助学生提高语言水平和考试成绩',
        // 	languageid: 1,
        // 	name: '金老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nan_jin.png',
        // 	typeName: '聚趣韩语高级讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'韩语教龄11年\n韩国留学4年\n韩语TOPIK五级、ITT 朝鲜语商务笔译2级\n擅长口语/听力/阅读专项课程、TOPIK考试课程',
        // 	languageid: 1,
        // 	name: '朴老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_piao.png',
        // 	typeName: '聚趣韩语资深讲师'
        // },
        // {
        // 	intro:
        // 		'韩语教龄5年\n毕业于首尔大学韩语专业，TOPIK六级持证人，曾在韩国多所大学进行语言交流研讨\n擅长韩语口语和听力教学，能够针对不同程度的学生制定个性化教学方案',
        // 	languageid: 1,
        // 	name: '崔老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_cui.png',
        // 	typeName: '聚趣韩语人气讲师'
        // },
        // // 男
        // {
        // 	intro:
        // 		'韩语教龄8年\n毕业于韩国成均馆大学，拥有良好的教育背景\n擅长韩国电视剧欣赏和口语对话练习\nHSK五级，中文熟练，能够为学生提供更好的沟通交流\n年轻有活力，深受学生喜爱',
        // 	languageid: 1,
        // 	name: '宋老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nan_song.png',
        // 	typeName: '聚趣韩语人气讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'韩语教龄8年\n韩语TOPIK四级、ITT 朝鲜语商务笔译1级\n韩国语言学习研究经验\n擅长口语/听力/阅读专项课程、TOPIK考试课程',
        // 	languageid: 1,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_wang.png',
        // 	typeName: '聚趣韩语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'韩语教龄7年\n韩语TOPIK六级、ITT 朝鲜语商务笔译3级\n韩国语言交流研究经验\n擅长语法/口语专项课程、TOPIK考试课程',
        // 	languageid: 1,
        // 	name: '成老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_cheng.png',
        // 	typeName: '聚趣韩语高级讲师'
        // },
        // {
        // 	intro:
        // 		'韩语教龄8年\nTOPIK六级持证人，曾担任职业韩语能力考试OK-TEST监考官。\n擅长韩语阅读和写作教学，善于培养学生的语法和词汇掌握能力。',
        // 	languageid: 1,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_zhang.png',
        // 	typeName: '聚趣韩语知名讲师'
        // },
        // // 男
        // {
        // 	intro:
        // 		'韩语教龄6年\n毕业于韩国延世大学，拥有博士学位\n擅长商务韩语培训和口语强化训练\nHSK六级，中文流利，能够为汉语母语学生提供更好的教学体验\n严谨认真，备课充分，深受学生信赖',
        // 	languageid: 1,
        // 	name: '元老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nan_yuan.png',
        // 	typeName: '聚趣韩语口碑讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'韩语教龄5年\n韩国留学4年\n韩语TOPIK五级、ITT 朝鲜语商务笔译1级\n韩国语言学习经验\n擅长口语/听力/阅读专项课程、TOPIK考试课程',
        // 	languageid: 1,
        // 	name: '全老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_quan.png',
        // 	typeName: '聚趣韩语口碑讲师'
        // },
        // {
        // 	intro:
        // 		'韩语教龄8年\nTOPIK六级持证人，曾在韩国留学并担任韩语课程研发\n擅长韩语初级和中级课程，注重培养学生的语感和语音准确性。同时，也能够根据学生需求提供定制化课程',
        // 	languageid: 1,
        // 	name: '赵老师',
        // 	pictureBig: 'https://qn.juquedu.com/k_tch_nv_zhao.png',
        // 	typeName: '聚趣韩语高级讲师'
        // }
      ],
      sTeacherList: [
        // 女
        // {
        // 	intro:
        // 		'西语教龄11年\n西班牙格拉纳达大学硕士\n持有高校教师资格证、DELE B1-B2考官证\n擅长西班牙语文化、商务西语、成人西语课程',
        // 	languageid: 2,
        // 	name: '何老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_he.png',
        // 	typeName: '聚趣西语高级讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄7年\n西班牙马德里大学本科\n持有高校教师资格证、DELE A1-A2考官证\n擅长西班牙语基础、青少年西语、西班牙文学',
        // 	languageid: 2,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_wang.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄9年\n西班牙牙巴塞罗那大学硕士\n持有高校教师资格证、DELE C1-C2考官证\n擅长西班牙语语法、西语口语强化、旅游西语',
        // 	languageid: 2,
        // 	name: '谢老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_xie.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄6年\n西班牙布尔戈斯大学本科\n持有高校教师资格证、DELE A1-B2考官证\n擅长西班牙语发音、西语初级、西班牙文化',
        // 	languageid: 2,
        // 	name: '刘老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_liu.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // // 男
        // {
        // 	intro:
        // 		'西语教龄6年\n西语DELE B2\n西班牙萨拉曼卡大学硕士\n西班牙留学工作近7年\n擅长西语A1-B2基础课程、商务西语课程',
        // 	languageid: 2,
        // 	name: '龙老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nan_long.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄11年\n西语DELE C2\n西班牙格拉纳达大学硕士\n西班牙留学工作近10年\n擅长西语A1-C2全阶段课程、西班牙文化课程',
        // 	languageid: 2,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nan_wang.png',
        // 	typeName: '聚学西语高级讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'西语教龄8年\n西班牙巴伦西亚大学硕士\n持有高校教师资格证、DELE A1-C2考官证\n擅长西班牙语口语、西语听力、商务西语',
        // 	languageid: 2,
        // 	name: '杨老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_yang.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄7年\n西班牙塞维利亚大学本科\n持有高校教师资格证、DELE A2-C2考官证\n擅长西班牙语阅读、西班牙语写作、西班牙文学',
        // 	languageid: 2,
        // 	name: '陈老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_chen.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // // 男
        // {
        // 	intro:
        // 		'西语教龄5年\n西语DELE B1\n西班牙马德里自治大学硕士\n西班牙留学工作近6年\n擅长西语A1-B2基础课程、旅游西语课程',
        // 	languageid: 2,
        // 	name: '李老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nan_li.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄7年\n西语DELE C1\n西班牙巴塞罗那大学硕士\n西班牙留学工作近8年\n擅长西语A1-C1全阶段课程、口语强化课程',
        // 	languageid: 2,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nan_zhang.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'西语教龄10年\n西班牙马德里康普顿斯大学硕士\n持有高校教师资格证、DELE B1-C2考官证\n擅长西班牙语听力、口语、西班牙文化',
        // 	languageid: 2,
        // 	name: '吴老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_wu.png',
        // 	typeName: '聚趣西语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'西语教龄优秀年\n西班牙塞维利亚大学本科\n持有高校教师资格证、DELE A1-B1考官证\n擅长西班牙语初级、旅游西语、西班牙文化',
        // 	languageid: 2,
        // 	name: '徐老师',
        // 	pictureBig: 'https://qn.juquedu.com/s_tch_nv_xu.png',
        // 	typeName: '聚趣西语优秀讲师'
        // }
      ],
      iTeacherList: [
        // 女
        {
          intro:
            "雅思教龄8年\n国内知名的雅思培训师，教学经验丰富，擅长对学员的个性化辅导，让学员更有针对性地提高雅思成绩。曾参与过多次出国考试，对相关流程非常熟悉。在多个培训机构担任高级讲师，教学效果卓越。",
          languageid: 6,
          name: "Olivia老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_olivia.png",
          typeName: "聚趣雅思优秀讲师",
        },
        {
          intro:
            "雅思教龄8年\n教学范围涵盖雅思口语、听力、阅读、写作等方面，曾多次参与国内外考试，对考试内容和出题规律了如指掌。在多个知名培训机构担任教研主管，有丰富的教学管理经验。深受学员们的喜爱和信赖。",
          languageid: 6,
          name: "李老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_li.png",
          typeName: "聚趣雅思人气讲师",
        },
        {
          intro:
            "雅思教龄10年\n拥有多年的雅思教学经验，教学方法灵活多样，注重培养学员的学习兴趣和实际应用能力。曾多次参与国内外雅思考试，对考试流程和出题规律非常熟悉。在多个知名培训机构担任高级讲师，受到学员们的一致好评。",
          languageid: 6,
          name: "Sophia老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_sophia.png",
          typeName: "聚趣雅思高级讲师",
        },
        // 男
        {
          intro:
            "雅思教龄11年\n新西兰奥克兰大学英语教育硕士，曾在新西兰多家语言学校任教，具有丰富的教学经验，擅长雅思口语和写作教学，注重培养学生的语言表达能力和思维逻辑能力。",
          languageid: 6,
          name: "吴老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nan_wu.png",
          typeName: "聚趣雅思高级讲师",
        },
        // 女
        {
          intro:
            "雅思教龄6年\n拥有多年的雅思教学经验，擅长对学员进行个性化辅导，帮助学员克服语言障碍，提高雅思成绩。曾多次参与国内外考试，对考试流程和出题规律了如指掌。在多个知名培训机构担任教研主管，深受学员们的信赖和喜爱。",
          languageid: 6,
          name: "Olivia",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_olivia(2).png",
          typeName: "聚趣雅思口碑讲师",
        },
        {
          intro:
            "雅思教龄11年\n美国加州大学洛杉矶分校英语教育硕士，TESOL国际教师资格证持有者，曾在国内多家知名教育机构任教，擅长雅思口语和听力教学，教学风格幽默轻松，深受学生喜爱。",
          languageid: 6,
          name: "王老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_wang.png",
          typeName: "聚趣雅思高级讲师",
        },
        // 男
        {
          intro:
            "雅思教龄7年\n北京外国语大学英语专业毕业，拥有丰富的教学经验，擅长雅思口语训练，曾参与多项雅思口语考试研究项目，深入研究口语评分标准和考试技巧，能够根据学生的个人情况量身定制口语训练方案，帮助学生在短时间内提高口语成绩。",
          languageid: 6,
          name: "赵老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nan_zhao.png",
          typeName: "聚趣雅思人气讲师",
        },
        // 女
        {
          intro:
            "雅思教龄5年\n英国伦敦大学英语语言学硕士，曾在英国多家语言学校任教，具有丰富的英语教学经验，擅长雅思阅读和写作教学，注重培养学生的思辨能力和语言表达能力。",
          languageid: 6,
          name: "张老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_zhang.png",
          typeName: "聚趣雅思人气讲师",
        },
        {
          intro:
            "雅思教龄10年\n澳大利亚悉尼大学英语教育硕士，曾在澳大利亚多家语言学校任教，具有丰富的留学和教学经验，擅长雅思听力和口语教学，注重培养学生的听说能力和语感。",
          languageid: 6,
          name: "李老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nv_li(2).png",
          typeName: "聚趣雅思高级讲师",
        },
        // 男
        {
          intro:
            "雅思教龄6年\n加拿大多伦多大学英语教育专业硕士，持有TESOL国际教师资格证，曾在英国从事英语教学工作多年，有着丰富的教学经验和独特的教学方法，擅长培养学生阅读理解和写作能力，深入了解雅思考试出题规律和解题技巧，被认为是雅思阅读和写作领域的权威。",
          languageid: 6,
          name: "郑老师",
          pictureBig: "https://qn.juquedu.com/i_tch_nan_zheng.png",
          typeName: "聚趣雅思口碑讲师",
        },
      ],
      rTeacherList: [
        // 女
        {
          intro:
            "俄语教龄8年\n毕业于南京师范大学俄语专业，曾在俄罗斯莫斯科留学两年。拥有多年的俄语教学经验，善于发掘学生的潜力，注重培养学生的听说能力。教学风格幽默轻松，深得学生喜爱。",
          languageid: 7,
          name: "安娜",
          pictureBig: "https://qn.juquedu.com/r_tch_nv_yuan.png",
          typeName: "聚趣俄语人气讲师",
        },
        // 男
        {
          intro:
            "俄语教龄6年\n俄国留学多年，掌握俄语语言知识和文化背景；曾在国内外多家知名企业担任俄语翻译和口译，精通俄语实际应用；在教学中注重帮助学生掌握俄语语言的实际应用能力，培养学生的听说读写综合能力；精通高考俄语和俄语水平考试，能够帮助学生制定科学的备考计划，并为学生提供课外拓展和实践机会。",
          languageid: 7,
          name: "马克西姆",
          pictureBig: "https://qn.juquedu.com/r_tch_nan_zeng.png",
          typeName: "聚趣俄语优秀讲师",
        },
        {
          intro:
            "俄语教龄4年\n本科毕业于中国传媒大学，硕士毕业于莫斯科国立大学。擅长俄语口语和听力训练。曾任教于中国外交部和俄罗斯驻华使馆。精通俄罗斯对外等级考试试题，中、高考俄语，公共俄语考试等。所带学员考试通过率为95%，为商务、旅游、留学等需求的学员提供专业的俄语学习支持。",
          languageid: 7,
          name: "维克托",
          pictureBig: "https://qn.juquedu.com/r_tch_nan_zhang.png",
          typeName: "聚趣俄语高级讲师",
        },
        // 女
        {
          intro:
            "俄语教龄11年\n毕业于上海外国语大学俄语专业，曾在莫斯科大学攻读研究生学位。具有多年教学经验，口语流利，语音纯正。注重启发学生思维，让学生在轻松愉悦的氛围中掌握俄语知识。",
          languageid: 7,
          name: "维罗妮卡",
          pictureBig: "https://qn.juquedu.com/r_tch_nv_wang.png",
          typeName: "聚趣俄语高级讲师",
        },
        {
          intro:
            "俄语教龄8年\n毕业于北京外国语大学俄语专业，曾在莫斯科大学深造。作为一名资深的俄语教师，刘老师注重培养学生的学习兴趣，擅长启发学生思维，帮助学生轻松掌握俄语知识。",
          languageid: 7,
          name: "达里娅",
          pictureBig: "https://qn.juquedu.com/r_tch_nv_liu.png",
          typeName: "聚趣俄语高级讲师",
        },
        {
          intro:
            "俄语教龄8年\n毕业于哈尔滨工业大学俄语专业，曾在俄罗斯莫斯科留学一年。拥有多年教学经验，具有深厚的俄语知识储备和翻译经验。注重培养学生的听说能力，让学生在轻松愉悦的氛围中学习俄语。",
          languageid: 7,
          name: "波莉娜",
          pictureBig: "https://qn.juquedu.com/r_tch_nv_yang.png",
          typeName: "聚趣俄语口碑讲师",
        },
        {
          intro:
            "俄语教龄8年\n俄罗斯莫斯科大学硕士，拥有深厚的俄语语言基础和丰富的教学经验。曾在大学和培训机构担任俄语教师，教授过不同层次的学生。张老师善于把俄语语言和俄罗斯文化相结合，帮助学生更好地理解和掌握俄语。针对不同需求的学生，张老师有针对性地制定教学计划，让学生轻松愉快地学习俄语。",
          languageid: 7,
          name: "瓦列莉亚",
          pictureBig: "https://qn.juquedu.com/r_tch_nv_zhang.png",
          typeName: "聚趣俄语口碑讲师",
        },
        // 男
        // {
        // 	intro:
        // 		'俄语教龄6年\n广州外国语学院俄语硕士。曾任教于俄罗斯国立外语大学。擅长俄语语法和写作训练。精讲新东方《大学俄语》系列教材，《俄语基础教程》等。精通俄罗斯对外等级考试试题，中、高考俄语',
        // 	languageid: 7,
        // 	name: '李老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nan_li.png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄5年\n本科毕业于北京语言大学，硕士毕业于圣彼得堡国立大学。曾任教于俄罗斯莫斯科国立大学。擅长俄语口语和听力训练，曾荣获俄罗斯语言文化协会优秀教师奖。精通俄罗斯对外等级考试试题，中、高考俄语，公共俄语考试等。所带学员考试通过率为96%，为旅游、文化交流等需求的学员提供专业的俄语学习支持。',
        // 	languageid: 7,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nan_wang.png',
        // 	typeName: '聚趣俄语人气讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'俄语教龄8年\n陈老师拥有多年的俄语教学经验，曾在大学和培训机构担任俄语教师。陈老师注重培养学生的听、说、读、写能力，教学内容涵盖了俄语语法、词汇、口语、阅读等方面。陈老师的教学风格活泼有趣，善于引导学生参与互动，让学生在轻松愉悦的氛围中学习俄语。',
        // 	languageid: 7,
        // 	name: '陈老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_chen.png',
        // 	typeName: '聚趣俄语人气讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄8年\n俄罗斯莫斯科国立大学硕士，具有深厚的俄语语言基础和丰富的文化背景。李老师曾在大学和培训机构担任俄语教师，教授过初、中、高级的学生。李老师以丰富的教学经验和敏锐的教学观察力为学生提供切实有效的俄语教学方案，让学生在轻松的氛围中快速提高俄语能力。',
        // 	languageid: 7,
        // 	name: '龚老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_gong.png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄8年\n俄罗斯莫斯科国立大学硕士，具有深厚的俄语语言基础和多年的教学经验。张老师注重培养学生的沟通能力和语言运用能力，能够针对学生的个性特点制定个性化的教学计划，让学生在轻松愉悦的氛围中学习俄语。张老师的教学风格幽默风趣，让学生在快乐中提高俄语能力。',
        // 	languageid: 7,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_zhang(2).png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // // 男
        // {
        // 	intro:
        // 		'俄语教龄11年\n本科毕业于北京外国语大学，硕士毕业于莫斯科国立大学。曾任教于俄罗斯莫斯科国立大学。擅长俄语语法和阅读训练，曾在俄罗斯出版社担任俄语教材编写工作。精通俄罗斯对外等级考试试题，中、高考俄语，公共俄语考试等。所带学员考试通过率为97%，为文学研究、出国深造等需求的学员提供专业的俄语学习支持。',
        // 	languageid: 7,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nan_zhang(2).png',
        // 	typeName: '聚趣俄语口碑讲师'
        // },
        // // 女
        // {
        // 	intro:
        // 		'俄语教龄8年\n俄罗斯圣彼得堡大学硕士，具有深厚的俄语语言基础和多年的教学经验。王老师注重培养学生的语感和语音准确性，能够通过生动有趣的教学方式激发学生的学习兴趣。王老师的教学风格严谨认真，让学生在严格要求下快速提高俄语能力。',
        // 	languageid: 7,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_wang(2).png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄8年\n重庆外国语学院俄语硕士，具有深厚的俄语语言基础和丰富的教学经验。李老师善于挖掘学生的潜力，通过个性化教学和多元化教学手段，激发学生的学习兴趣，让学生在愉悦的氛围中提高俄语能力。李老师的教学风格灵活多变，让学生在不同的教学场景中快速提升俄语能力。',
        // 	languageid: 7,
        // 	name: '潘老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_pan.png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄10年\n上海外国语大学俄语硕士，具有扎实的语言基础与深厚的文化底蕴；曾在俄语培训机构任职多年，有丰富的教学经验和成功案例；教学风趣幽默，注重培养学生的语感和口语实际应用能力；在教学中注重与学生的互动，定期组织文化活动，让学生在轻松愉快的氛围中掌握俄语知识。',
        // 	languageid: 7,
        // 	name: '张老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_zhang(3).png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄7年\n毕业于国内著名师范院校俄语专业，拥有多年的教学经验和学习经历；在教学中注重俄语语法的系统讲解，培养学生的语言思维能力和翻译能力；善于利用多媒体教学手段，提高学生的学习兴趣和效果；为学生量身定制学习计划，帮助学生快速提高俄语水平。',
        // 	languageid: 7,
        // 	name: '马老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_ma.png',
        // 	typeName: '聚趣俄语优秀讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄12年\n毕业于国内外多所知名大学，掌握俄语语言知识和文化背景；曾在国内外多家知名企业担任俄语翻译和口译，精通俄语实际应用；在教学中注重帮助学生掌握俄语语言的实际应用能力，培养学生的听说读写综合能力；精通高考俄语和俄语水平考试，能够帮助学生制定科学的备考计划。',
        // 	languageid: 7,
        // 	name: '王老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_wang(3).png',
        // 	typeName: '聚趣俄语高级讲师'
        // },
        // {
        // 	intro:
        // 		'俄语教龄8年\n在国内外多所知名大学深入学习俄语语言和文化，掌握俄语语言知识和文化背景；曾在俄语培训机构和国际学校担任俄语教师，有多年的教学经验和成功案例；在教学中注重培养学生的语感和口语实际应用能力，为学生提供多种实用的俄语口语和写作技巧；精通高考俄语和俄语水平考试，能够帮助学生制定科学的备考计划。',
        // 	languageid: 7,
        // 	name: '钱老师',
        // 	pictureBig: 'https://qn.juquedu.com/r_tch_nv_qian.png',
        // 	typeName: '聚趣俄语高级讲师'
        // }
      ],
      germanTeacherList: [],
      frenchTeacherList: [],
      japaneseTeacherList: [],
      koreanTeacherList: [],
      spanishTeacherList: [],
      ieltsTeacherList: [],
      russianTeacherList: [],
      timer: null,
      russian: {
        languageid: 7,
        appName: "俄语",
        appNameE: "RUSSIAN",
        showTag: 1,
      },
    };
  },
  created() {
    //解决首次加载 未获取 schoolAppMo
    if (sessionStorage.getItem("schoolAppMo")) {
      this.classificationList = JSON.parse(sessionStorage.getItem("schoolAppMo"));
      this.classificationList.push(this.russian);
      this.teacherClassification = "德语";
      this.teacherClassificationE = "GERMAN";
    } else {
      this.timer = setInterval(() => {
        let sh = JSON.parse(sessionStorage.getItem("schoolAppMo"));
        if (sh) {
          this.classificationList = sh;
          this.classificationList.push(this.russian);
          this.teacherClassification = "德语";
          this.teacherClassificationE = "GERMAN";
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 300);
    }
    this.getTeacherIntroList();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    this.modularList.forEach((item) => {
      var name = item.name;
      if (name == "img") {
        item.scroll = this.$refs[name].getBoundingClientRect().top + window.scrollY;
      } else {
        item.scroll = this.$refs[name].getBoundingClientRect().top + window.scrollY - 64;
      }
    });
  },
  destroyed() {
    // 在组件生命周期结束的时候销毁。
    window.removeEventListener("scroll", this.windowScroll);
  },
  methods: {
    // 获取师资
    async getTeacherIntroList() {
      var params = new URLSearchParams();
      params.append("page", 0);
      params.append("size", 99999);
      params.append("sort", "sort,asc");
      const { data: res } = await this.$http.post(
        "school/schoolTeacherIntro/un/getlistv2",
        params
      );
      if (res.status == 1001) {
        var data = [];
        data = res.data.content;
        if (data.length) {
          data.forEach((item, i) => {
            var languageid = item.languageid;
            switch (languageid) {
              case 4:
                this.germanTeacherList.push(item);
                break;
              case 5:
                this.frenchTeacherList.push(item);
                break;
              case 3:
                this.japaneseTeacherList.push(item);
                break;
              case 1:
                this.koreanTeacherList.push(item);
                break;
              case 2:
                this.spanishTeacherList.push(item);
                break;
              case 6:
                var intro = "";
                var list = [];
                if (item.intro.includes("<标>")) {
                  list = item.intro.split("<标>");
                  for (let index = 1; index < list.length; index++) {
                    intro = list[index].split("<签>")[1].replace(/^\s*/, ""); //获取最后一个<签>并且去除空格换行
                  }
                  item.intro = intro;
                }
                this.ieltsTeacherList.push(item);
                break;
              case 7:
                this.russianTeacherList.push(item);
                break;
            }
            if (i == data.length - 1) {
              var differ = 0;
              var arr = [];
              if (this.germanTeacherList.length < 22) {
                differ = 22 - this.germanTeacherList.length;
                arr = this.gTeacherList.slice(0, differ);
                this.germanTeacherList = this.germanTeacherList.concat(arr);
              }
              if (this.frenchTeacherList.length < 20) {
                differ = 20 - this.frenchTeacherList.length;
                arr = this.fTeacherList.slice(0, differ);
                //this.frenchTeacherList = this.frenchTeacherList.concat(arr)
              }
              if (this.japaneseTeacherList.length < 24) {
                differ = 24 - this.japaneseTeacherList.length;
                arr = this.jTeacherList.slice(0, differ);
                //this.japaneseTeacherList = this.japaneseTeacherList.concat(arr)
              }
              if (this.koreanTeacherList.length < 22) {
                differ = 22 - this.koreanTeacherList.length;
                arr = this.kTeacherList.slice(0, differ);
                //this.koreanTeacherList = this.koreanTeacherList.concat(arr)
              }
              if (this.spanishTeacherList.length < 17) {
                differ = 17 - this.spanishTeacherList.length;
                arr = this.sTeacherList.slice(0, differ);
                //this.spanishTeacherList = this.spanishTeacherList.concat(arr)
              }
              if (this.ieltsTeacherList.length < 21) {
                differ = 21 - this.ieltsTeacherList.length;
                arr = this.iTeacherList.slice(0, differ);
                //this.ieltsTeacherList = this.ieltsTeacherList.concat(arr)
              }
              if (this.russianTeacherList.length < 19) {
                differ = 19 - this.russianTeacherList.length;
                arr = this.rTeacherList.slice(0, differ);
                //this.russianTeacherList = this.russianTeacherList.concat(arr)
              }
            }
          });
          this.teacherList = this.germanTeacherList;
          this.teacherInfo = this.teacherList[0];
        }
      }
    },
    // 监听页面滚动 获取滚动条位置
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        scrollTop >= this.$refs.img.getBoundingClientRect().top + window.scrollY &&
        scrollTop < this.$refs.juqu.getBoundingClientRect().top + window.scrollY - 300
      ) {
        this.scrollItem = "img";
      } else if (
        scrollTop >= this.$refs.juqu.getBoundingClientRect().top + window.scrollY - 300 &&
        scrollTop < this.$refs.teacher.getBoundingClientRect().top + window.scrollY - 300
      ) {
        this.scrollItem = "juqu";
        this.juquImg = true;
        setTimeout(() => {
          this.juquTime = 1;
        }, 1000);
      } else if (
        scrollTop >=
        this.$refs.teacher.getBoundingClientRect().top + window.scrollY - scrollTop <
        this.$refs.language.getBoundingClientRect().top + window.scrollY - 300
      ) {
        this.scrollItem = "teacher";
        setTimeout(() => {
          this.teacherTime = 1;
        }, 1000);
      } else if (
        scrollTop >=
          this.$refs.language.getBoundingClientRect().top + window.scrollY - 300 &&
        scrollTop < this.$refs.media.getBoundingClientRect().top + window.scrollY - 300
      ) {
        this.scrollItem = "language";
        setTimeout(() => {
          this.languageTime = 1;
        }, 1000);
      } else {
        this.scrollItem = "media";
        setTimeout(() => {
          this.mediaTime = 1;
        }, 1000);
      }
      // console.log(this.scrollItem)
    },
    // 选择语言
    selectTeacherClassification(value) {
      if (value.appName != this.teacherClassification) {
        this.teacherClassification = value.appName;
        this.teacherClassificationE = value.appNameE;
        this.teacherIndex = 0;
        switch (value.languageid) {
          case 4:
            this.teacherList = this.germanTeacherList;
            break;
          case 5:
            this.teacherList = this.frenchTeacherList;
            break;
          case 3:
            this.teacherList = this.japaneseTeacherList;
            break;
          case 1:
            this.teacherList = this.koreanTeacherList;
            break;
          case 2:
            this.teacherList = this.spanishTeacherList;
            break;
          case 6:
            this.teacherList = this.ieltsTeacherList;
            break;
          case 7:
            this.teacherList = this.russianTeacherList;
            break;
        }
        this.teacherInfo = this.teacherList[0];
      }
    },
    // 选择老师
    selectTeacherInfo(index, info) {
      this.teacherIndex = index;
      this.teacherInfo = info;
    },
    goToWebsite(url) {
    if(url){
      window.open(url);
    }
    },
  },
};
</script>

<style lang="scss" scoped>
// 关于聚趣
.juqu_out {
  width: 100%;
  background: #f6faff;
  position: relative;
  padding-bottom: 16px;
  .juqu {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 1;
    .juqu_content_out {
      width: 100%;
      color: #333333;
      .juqu_content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        .juqu_content_intro {
          font-weight: bold;
          font-size: 18px;
          padding-bottom: 12px;
          line-height: 26px;
        }
        .juqu_content_desc {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .juqu_content_type {
          width: 100%;
          padding-top: 30px;
          .juqu_content_type_item {
            height: 95px;
            img {
              width: 30px;
              height: 30px;
            }
            .juqu_content_type_item_value {
              padding: 2px 0;
              font-weight: bold;
              font-size: 12px;
              span {
                font-size: 20px;
              }
            }
            .juqu_content_type_item_name {
              width: 50px;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
      .juqu_content_img {
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
      }
    }
  }
  .juqu_app {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    .juqu_app_img {
      width: 120px;
      height: 144px;
      position: absolute;
    }
    .item_img1 {
      top: -38px;
      left: -25px;
    }
    .item_img2 {
      top: -104px;
      left: 96px;
    }
    .item_img3 {
      top: 95px;
      left: -24px;
    }
    .item_img4 {
      top: -75px;
      right: -7px;
    }
    .item_img5 {
      top: -75px;
      right: -88px;
    }
    .item_img6 {
      top: 46px;
      right: -16px;
    }
    .item_img7 {
      top: 46px;
      right: -96px;
    }
  }
}
.bg_title_out {
  overflow: hidden;
  color: #333333;
  position: relative;
  left: 4px;
  .bg_title {
    font-weight: 800;
    font-size: 60px;
    color: #999999;
    opacity: 0.1;
    position: relative;
    div {
      span {
        font-size: 80px;
      }
    }
  }
  .title_out {
    position: absolute;
    left: 12px;
    .big_title {
      font-weight: bold;
      font-size: 20px;
    }
    .small_title {
      font-weight: 400;
      font-size: 12px;
      padding-top: 4px;
    }
  }
}
// .title_apear {
// 	animation: title_apear 1s forwards;
// 	display: inline-block;
// 	margin: 0;
// 	transform: translateY(136px);
// 	-ms-transform: translateY(136px);
// 	-webkit-transform: translateY(136px); /*兼容-webkit-引擎浏览器*/
// 	-moz-transform: translateY(136px); /*兼容-moz-引擎浏览器*/
// }
// @keyframes title_apear {
// 	0% {
// 		opacity: 0;
// 		transform: translateY(136px);
// 	}
// 	100% {
// 		opacity: 1;
// 		transform: translateY(0);
// 	}
// }
// .bg_title_apear {
// 	animation: bg_title_apear 1s forwards;
// 	display: inline-block;
// 	margin: 0;
// 	transform: translateY(136px);
// 	-ms-transform: translateY(136px);
// 	-webkit-transform: translateY(136px); /*兼容-webkit-引擎浏览器*/
// 	-moz-transform: translateY(136px); /*兼容-moz-引擎浏览器*/
// }
// @keyframes bg_title_apear {
// 	0% {
// 		opacity: 0;
// 		transform: translateY(136px);
// 	}
// 	100% {
// 		opacity: 0.1;
// 		transform: translateY(0);
// 	}
// }
.img_apear {
  animation: img_apear 1s forwards;
  margin: 0;
  transform: translateY(500px);
  -ms-transform: translateY(500px);
  -webkit-transform: translateY(500px); /*兼容-webkit-引擎浏览器*/
  -moz-transform: translateY(500px); /*兼容-moz-引擎浏览器*/
}
@keyframes img_apear {
  from {
    top: -500px;
    transform: translateX(500px);
  }
  to {
    transform: translateY(0);
    transform: translateX(0);
  }
}
// 聚趣名师
.teacher_out {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .teacher_classification_out {
    width: 100%;
    margin: 0 auto 14px;
    .teacher_classification_list {
      height: 35px;
      padding: 0 14px 10px !important;
      box-sizing: border-box;
      .teacher_classification {
        width: 30px;
        font-size: 14px;
        padding-right: 20px;
        cursor: pointer;
        position: relative;
        .teacher_classification_active {
          width: 25px;
          margin: auto;
          content: "";
          position: absolute;
          bottom: -10px;
          display: block;
          height: 3px;
          text-align: center;
          background: #333333;
          animation: fadeInLeft 0.3s ease 0.2s 1 both;
        }
      }
      .teacher_classification:last-child {
        padding-right: 0;
      }
    }
  }
  .teacher_info {
    width: 100%;
    height: 100%;
    .teacher_img_out2 {
      width: 100%;
      height: 100%;
      background: #f2f2f3;
    }
    .teacher_img_out {
      width: 100%;
      height: 100%;
      padding-top: 44px;
      position: relative;
      .teacher_bg {
        width: 98%;
        position: relative;
        left: 2%;
      }
      .teacher_img {
        width: calc(100% / 1.6);
        position: absolute;
        left: calc(100% / 3.95);
        top: 0;
      }
      .teacher_name_out {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #ffffff;
        .teacher_name_bg {
          width: 100%;
        }
        .teacher_name_box {
          width: 100%;
          height: 100%;
          padding: 11px 20px;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          .teacher_name {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 4px;
          }
          .teacher_des {
            font-size: 14px;
            font-weight: 300;
            white-space: pre-line;
          }
        }
      }
    }
    .teacher_details {
      height: 100%;
      padding: 12px 20px 0;
      box-sizing: border-box;
      color: #333333;
      position: relative;
      background: #ffffff;
      .teacher_introduce {
        font-size: 14px;
        font-weight: 400;
        white-space: pre-line;
      }
    }
  }
  .teacher_text_classification {
    font-size: 240px;
    font-weight: 800;
    color: #333333;
    opacity: 0.1;
    position: absolute;
    right: 22px;
    top: 343px;
  }
  .teacher_list_out_out {
    width: 100%;
    // height: 52px;
    padding: 16px 0 20px;
    background: #ffffff;
    position: relative;
    margin-top: -1px;
    .teacher_list_out {
      .teacher_list {
        // height: 52px;
        padding: 0 0 0 14px !important;
        box-sizing: border-box;
        .teacher_imgs {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          border-radius: 50%;
          cursor: pointer;
        }
        .teacher_imgs:last-child {
          margin-right: 0;
        }
        .teacher_imgs2 {
          width: 48px;
          height: 65.6px;
          float: left;
          margin: 0 1px 1px 0;
          background: #f2f2f3;
          border-radius: 4px;
          cursor: pointer;
          position: relative;
          .teacher_imgs2_bg {
            width: 100%;
            height: 100%;
            display: none;
            position: absolute;
            background: #000000;
            opacity: 0.16;
          }
          .teacher_imgs2_bg2 {
            display: block;
          }
        }
        .teacher_imgs2:hover {
          .teacher_imgs2_bg {
            display: block;
          }
          .teacher_imgs2_bg2 {
            display: none;
          }
        }
        .teacher_active {
          width: 48px;
          height: 67px;
        }
      }
    }
  }
}
// 聚趣小语种
.language_out {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #ffffff;
  position: relative;
  margin-top: -1px;
  .language {
    width: 100%;
    margin-top: 24px;
    position: relative;
    overflow-x: auto; /* 隐藏超出部分 */
    white-space: nowrap; /* 禁止换行 */
    img {
      width: 607px;
      // display: inline-block; /* 使图片作为行内块显示 */
      // position: relative; /* 定义位置参照 */
      // vertical-align: middle; /* 垂直居中 */
      margin: 0 auto;
    }
  }
}
// 媒体报道
.media_out {
  width: 100%;
  height: 300px;
  display: table;
  padding-bottom: 30px;
  .media_title_out {
    margin-bottom: 24px;
    .media_title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
  }
  .media_list {
    width: 100%;
    padding: 0 4%;
    box-sizing: border-box;
    .media_item {
      width: 31%;
      float: left;
      margin: 0 3.5% 18px 0;
    }
  }
}
</style>
